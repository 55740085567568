const demandKAMRoutes = {
  path: "/demand_kam/",
  component: () => import("layouts/DemandKAMLayout.vue"),
  children: [
    {
      path: "dashboard",
      name: "DemandKAMDashboardPage",
      component: () => import("pages/demandKAM/KAMDashboardPage.vue"),
    },
    {
      path: "skus",
      name: "DemandKAMSKUs",
      children: [
        {
          path: "seller_skus/live",
          name: "DemandKAMViewLiveSellerSKUs",
          component: () => import("pages/demandKAM/KAMViewLiveSellerSKUs.vue"),
        },
        {
          path: "bidso_skus",
          name: "DemandKAMManageBidsoSKUs",
          component: () => import("pages/demandKAM/KAMManageBidsoSKUs.vue"),
        },
        {
          path: "buyer_skus",
          name: "DemandKAMBuyerSKUs",

          children: [
            {
              path: "",
              name: "DemandKAMViewBuyerSKUs",
              component: () => import("pages/demandKAM/KAMViewBuyerSKUs.vue"),
            },
            {
              path: "create",
              name: "DemandKAMCreateBuyerSKU",
              component: () => import("pages/demandKAM/KAMCreateBuyerSKU.vue"),
            },
            {
              path: ":buyerSKUId/edit",
              name: "DemandKAMEditBuyerSKU",
              component: () => import("pages/demandKAM/KAMEditBuyerSKU.vue"),
            },
            {
              path: "pricing_requests",
              name: "DemandKAMBuyerSKUPriceChangeRequests",
              component: () =>
                import("pages/demandKAM/KAMBuyerSKUPriceChangeRequests.vue"),
            },
          ],
        },
        {
          path: "sample_skus",
          name: "DemandKAMSampleSKUs",

          children: [
            {
              path: "",
              name: "DemandKAMViewSampleSKUs",
              component: () => import("pages/demandKAM/KAMViewSampleSKUs.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "categories/:categoryId/skus/compare",
      name: "DemandKAMCompareSKUs",
      component: () => import("pages/demandKAM/KAMCompareSKUs.vue"),
    },

    {
      path: "profile",
      name: "DemandKAMEditProfile",
      component: () => import("pages/demandKAM/KAMProfile.vue"),
    },
    {
      path: "buyers/manage",
      name: "KAMManageBuyers",
      component: () => import("pages/demandKAM/KAMManageBuyers.vue"),
    },

    {
      path: "order_cart",
      name: "DemandKAMCartPage",
      component: () => import("pages/demandKAM/KAMCartPage.vue"),
    },
    {
      path: "samples_cart",
      name: "DemandKAMSamplesCartPage",
      component: () => import("pages/demandKAM/KAMSamplesCartPage.vue"),
    },
    {
      path: "master_orders",
      name: "DemandKAMMasterOrders",
      children: [
        {
          path: "",
          name: "KAMMasterOrdersSearch",
          component: () => import("pages/demandKAM/KAMMasterOrdersSearch.vue"),
        },
        {
          path: "qc/reports",
          name: "KAMQCReports",
          component: () => import("pages/demandKAM/KAMQCReports.vue"),
        },
        {
          path: "dispatch/calendar",
          name: "KAMOrderDispatchCalendar",
          component: () =>
            import("pages/demandKAM/KAMOrderDispatchCalendar.vue"),
        },

        {
          path: "logistics/calendar",
          name: "KAMLogisticsCalendar",
          component: () => import("pages/demandKAM/KAMLogisticsCalendar.vue"),
        },
      ],
    },
    {
      path: "master_orders/:masterOrderId",
      name: "DemandKAMViewMasterOrder",
      component: () => import("pages/demandKAM/KAMViewMasterOrder.vue"),
    },
    {
      path: "sample_orders",
      name: "KAMManageSampleOrders",
      component: () => import("pages/demandKAM/KAMManageSampleOrders.vue"),
    },
    {
      path: "sample_orders/:sampleOrderId",
      children: [
        {
          path: "",
          name: "DemandKamManageSampleOrder",
          component: () => import("pages/demandKAM/KAMManageSampleOrder.vue"),
        },
      ],
    },
    {
      path: "buyers/:buyerId/",
      children: [
        {
          path: "",
          name: "DemandKAMViewBuyer",
          component: () => import("pages/demandKAM/KAMBuyerProfile.vue"),
        },

        {
          path: "edit",
          name: "DemandKAMEditBuyer",
          component: () => import("pages/demandKAM/KAMEditBuyer.vue"),
        },
      ],
    },
  ],
};
export default demandKAMRoutes;
